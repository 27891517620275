import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { headerAllHeight, headerHeightSm, maxWidth } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { zIndex } from '@themes/zIndex';

export default makeStyles<Theme, { menuBarColor?: string; menuBarBackground?: string }>((theme) =>
  createStyles({
    '& div': {
      background: ColorsPalette.neutral2,
    },
    container: {
      margin: '0 auto',
      maxWidth: '1222px',
      padding: '1rem',
      position: 'relative',
    },
    header: {
      backgroundColor: ColorsPalette.neutral2,
      display: 'flex',
      flexDirection: 'column',
      height: headerAllHeight,
      position: 'sticky',
      transition: 'all 0.1s ease-out',
      width: '100%',
      zIndex: zIndex.header,
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        height: headerHeightSm,
        justifyContent: 'flex-start',
      },
    },
    headerCompensation: {
      height: 88,
      [theme.breakpoints.down('md')]: {
        height: 48,
      },
    },
    headerContainer: {
      display: 'flex',
      height: 88,
      margin: '0 auto',
      maxWidth,
      width: '100%',
    },
    headerWrapFixed: {
      top: 0,
    },
    headerWrapFixedMM: {
      position: 'fixed',
    },
    headerWrapUnpinned: {

      top: -130,

      [theme.breakpoints.down('md')]: {

        top: -60,
      },
    },
    mobileMenuBar: {
      '& svg path': {
        fill: (props) => props.menuBarColor || ColorsPalette.neutral2,
      },
      alignItems: 'center',
      backgroundColor: (props) => props.menuBarBackground || ColorsPalette.primary,
      display: 'flex',
      flex: '0 0 48px',
      height: '48px',
      justifyContent: 'center',
      width: '48px',
    },
  }),
);
