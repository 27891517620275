import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import CustomBanners from '@components/common/customBanners';
import Hidden from '@components/common/Hidden';
import LayoutAppScrollContext from '@components/layout/FixContext';
import MobileMenuWrapper from '@components/layout/header/MobileMenu/wrapState';
import LayoutMainHomeBg from '@components/layout/homeBg';
import MobileMenuRestore from '@components/main/MobileMenuRestore';
import { ECustomBannerType } from '@constants/customBanners';
import { PageTypes } from '@constants/pageTypes';
import { useTranslation } from '@hooks/useTranslation';
import { IStore } from '@interfaces/store';
import layoutStylesCommon from '@themes/layout';
import { getFrontpageColor } from '@utils/siteSettings';
import Header from './header/Header';
import PageHeaderNotification from '@components/common/PageHeaderNotification';

const MobileSearch = dynamic(() => import('@components/layout/header/AppBar/Search/MobileSearch/MobileSearch'), { ssr: false });
const CountryLanguage = dynamic(() => import('@components/dialogs/CountryLanguage'), { ssr: false });
const Portal = dynamic(() => import('@components/layout/header/AppBar/Search/MobileSearch/withPortal'), { ssr: false });
const TranslationModal = dynamic(() => import('@components/common/translation-button/modal'), { ssr: false });
const Footer = dynamic(() => import('@components/layout/Footer'), { ssr: false });

import LayoutStyles from './styles';

interface ILayoutProps {
  children: any;
}

const LayoutMain: React.FunctionComponent<ILayoutProps> = ({ children }) => {

  const data = useSelector((state: IStore) => state.pageData.data);
  const pageType = useSelector((state: IStore) => state.pageData.type);
  const mobileMenuOpen = useSelector((state: IStore) => state.page.mobileMenu.open);

  const classes = LayoutStyles({ backgroundColor: getFrontpageColor(data) });
  const classesLayout = layoutStylesCommon();
  const { isEditMode } = useTranslation();

  const isProductsPage = pageType === PageTypes.productsList;
  const isContactsPage = pageType === PageTypes.contacts;

  const contentRef = useRef<HTMLObjectElement | null>(null);

  return (
    <LayoutAppScrollContext>
        <PageHeaderNotification />
        <CustomBanners location={ECustomBannerType.page_header} />
        {pageType === 'home' && <LayoutMainHomeBg />}
        <Header />
        <Hidden mdUp={true}>
          <MobileMenuRestore>
            <MobileMenuWrapper />
          </MobileMenuRestore>
        </Hidden>
        <main
          ref={contentRef}
          className={classNames(
            classesLayout.pageSize,
            classes.body,
            { [classes.productsPage]: isProductsPage },
            { [classes.contactsPage]: isContactsPage },
            { [classes.bodyLock]: mobileMenuOpen },
          )}
        >
          {children}
          {isEditMode && <TranslationModal />}
        </main>
        <Footer />
        <Hidden mdUp={true}>
          <Portal>
            <MobileSearch />
          </Portal>
        </Hidden>
        <CountryLanguage />
    </LayoutAppScrollContext>
  );
};

export default LayoutMain;
