import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

type IStyleProps = Record<string, unknown>;

export default makeStyles<Theme, IStyleProps>(() =>
  createStyles({
    banner: {
      paddingBottom: '16px',
      width: '100%',
    },
    bannerImages: {
      width: '100%',
    },
    notification_error: {
      color: ColorsPalette.error1,
    },
    notification_normal: {},
  })
);
