import React from 'react';

import Hidden from '@components/common/Hidden';
import { mobileMode } from '@constants/config';
import { getMediaUrl } from '@constants/urls';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import Link from '../Link';
import SwiperComponent from '../Swiper';
import styles from './styles';
import { TGetBanners, TImageType, TRenderChildren } from './types';

interface Params {
  banners: ICustomBanner[];
}

const renderLink = (banner: ICustomBanner, children: JSX.Element) => {
  if (!banner.link) {
    return children;
  }

  if (banner.open_new_window) {
    return <a href={banner.link} target="_blank">{children}</a>;
  }

  return <Link href={banner.link} >{children}</Link>;
};

const CustomBannerTypeBanner: React.FC<Params> = ({ banners }) => {
  const classes = styles({});

  const renderHiddenBlock = (getBanners: TGetBanners, renderChildren: TRenderChildren) => (<>
    {!mobileMode && banners?.[0]?.image && (
      <Hidden mdDown>
        {renderChildren('image', getBanners('image'))}
      </Hidden>
    )}
    {!mobileMode && banners?.[0]?.image_mobile && (
      <Hidden mdUp>
        {renderChildren('image_mobile', getBanners('image_mobile'))}
      </Hidden>
    )}
    {mobileMode && banners?.[0]?.image_app && (
      <Hidden mdUp>
        {renderChildren('image_app', getBanners('image_app'))}
      </Hidden>
    )}
  </>);

  const renderImage = (image: string | undefined) => {
    if (!image) {
      return <></>;
    }
    return <img src={getMediaUrl(image)} width="100%" height="auto" />;
  };

  if (banners.length === 1) {
    return renderHiddenBlock(() => banners, (type: TImageType) => renderLink(banners[0], renderImage(banners[0][type])));
  }

  return (
    <div className={classes.bannerImages}>
      {renderHiddenBlock(
        (imageType: TImageType) => banners.filter((item) => item[imageType] !== null),
        (imageType: TImageType, cBanners: ICustomBanner[]) => (
          <SwiperComponent
            autoplay={true}
            delay={5000}
            loop={true}
            navigation={false}
            productList={false}
            slidesPerView={1}
            slides={cBanners.map((banner) => (
              <div key={banner[imageType]}>
                {renderImage(banner[imageType])}
              </div>
            ))}
          />
        ))}
    </div>
  );
};

export default CustomBannerTypeBanner;
