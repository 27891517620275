import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import GoTopButton from '@components/common/buttons/GoTop/mobile';
import CountryLanguageSelect from '@components/dialogs/CountryLanguage';
import LayoutAppScrollContext from '@components/layout/FixContext';
import MobileSearch from '@components/layout/header/AppBar/Search/MobileSearch/MobileSearch';
import Portal from '@components/layout/header/AppBar/Search/MobileSearch/withPortal';
import HeaderApp from '@components/layout/MobileApp/Header/component';
import NavigationBar from '@components/layout/MobileApp/NavigationBar';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { useTranslation } from '@hooks/useTranslation';
import { IStore } from '@interfaces/store';

const TranslationModal = dynamic(() => import('@components/common/translation-button/modal'),{ ssr: false });

import layoutStylesCommon from '@themes/layout';
import LayoutStyles from './styles';

interface ILayoutProps {
  children: any;
  loading?: boolean;
}

const LayoutApp: React.FunctionComponent<ILayoutProps> = ({ children, loading }) => {
  const classes = LayoutStyles({ backgroundColor: 'transparent' });
  const classesLayout = layoutStylesCommon();
  const { isEditMode } = useTranslation();

  const pageType = useSelector((state: IStore) => state.pageData.type);

  const isHeaderShow = useMemo(() => ![PageTypes.menu, PageTypes.home].includes(pageType as PageTypes), [pageType]);
  const isGoTopShow = useMemo(() => ![PageTypes.menu, PageTypes.cart].includes(pageType as PageTypes), [pageType]);

  return (
    <>
      <LayoutAppScrollContext>
        <main
          className={classNames(
            classesLayout.pageSize,
            classes.body,
            { [classes.bodyWithoutHeader]: !isHeaderShow,
              [classesLayout.pageSizeMobile]: mobileMode },
          )}
        >
          {isHeaderShow && (<HeaderApp />)}
          {children}
          {isEditMode && <TranslationModal />}
        </main>
        {mobileMode && (<NavigationBar />)}
        <CountryLanguageSelect />
        <Portal>
          <MobileSearch />
        </Portal>
        {isGoTopShow && (
          <GoTopButton />
        )}
      </LayoutAppScrollContext>
    </>
  );
};

export default LayoutApp;
