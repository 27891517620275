import React from 'react';
import { useSelector } from 'react-redux';

import { ECustomBannerType } from '@constants/customBanners';
import { ICustomBanner, ICustomBannerClosed } from '@interfaces/customBanners/actions';
import PageHeaderNotificationItem from './notificationItem';
import { IStore } from '@interfaces/store';


const PageHeaderNotification: React.FunctionComponent = () => {
  const customBanners = useSelector<IStore, ICustomBanner[]>((state) => state.pageData.data.customBanners?.[ECustomBannerType.page_header] || []);
  const closedNotifications = useSelector<IStore, ICustomBannerClosed[]>((state) => state.common.closedNotifications);

  const closedNotificationsId = closedNotifications.map((item) => item.id + '');
  const notifications = customBanners
    .filter((item) => item.type === 'notification')
    .filter((item) => !closedNotificationsId.includes(item.uid + ''));

  return notifications.length > 0 ? (
    <>
      {notifications.map((item, key) =>
        <PageHeaderNotificationItem
          key={`PageHeaderNotificationItem${key}`}
          item={item} />)}
    </>
  ) : null;
};

export default PageHeaderNotification;
