import classNames from 'classnames';
import React, { useCallback } from 'react';

import { acNotificationClose } from '@actions/acCustomBanners';
import { TThunkDispatch } from '@interfaces/index';
import { pushOrReloadRoute } from '@utils/routes';
import { CloseIcon } from '@icons/Close';
import { ColorsPalette } from '@themes/colors';
import HiddenMy from '@components/common/Hidden';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import { useDispatch } from 'react-redux';
import styles from './styles';

interface IPageHeaderNotificationItemProps {
  item: ICustomBanner;
}

const PageHeaderNotificationItem: React.FunctionComponent<IPageHeaderNotificationItemProps> = ({ item }) => {
  const classes = styles({});
  const { link, open_new_window } = item;

  const onClick = useCallback(() => {
    if (link) {
      if (open_new_window && window) {
        window.open(link, '_blank');
      } else {
        pushOrReloadRoute(link);
      }
    }
  }, [link, open_new_window]);

  return (
    <HiddenMy smDown={!item.show_in_mobile} smUp={!item.show}>
      <div className={classNames(classes.notification, { [classes.notificationLink]: link })}
           style={{
             color: item.color || ColorsPalette.primary,
             backgroundColor: item.colorBackground || ColorsPalette.coralPink,
             fontSize: item.fontSize || '14px',
           }}
           onClick={onClick}
      >
        <div className={classes.notificationContent}>
          <div
            className={classNames(classes.notificationContentText, { [classes.notificationContentWithClose]: true })}>
            {item?.text}
          </div>
          {item.closeButton && <PageHeaderNotificationItemClose item={item} />}
        </div>
      </div>
    </HiddenMy>
  );
};

const PageHeaderNotificationItemClose: React.FunctionComponent<IPageHeaderNotificationItemProps> = ({ item }) => {
  const classes = styles({ color: item.color });

  const dispatch = useDispatch<TThunkDispatch>();
  const onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(acNotificationClose(item));
  }, [item]);

  return (
    <div className={classes.notificationClose} onClick={onClick}>
      <CloseIcon className={classes.notificationCloseIcon} />
    </div>

  );
};

export default PageHeaderNotificationItem;
