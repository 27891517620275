import { createStyles, makeStyles } from '@mui/styles';
import Link from '@components/common/Link';
import { Theme } from '@mui/material/styles';
import { ColorsPalette } from '@themes/colors';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import { useEffect, useState } from 'react';

interface Params {
  banners: ICustomBanner[];
}

const CustomBannerTypeTopCampaign: React.FC<Params> = ({ banners }) => {
  return (
    <>
      {banners.map((banner) => (
        <TopCampaign banner={banner} key={banner.text} />
      ))}
    </>
  );
};

const TopCampaign: React.FC<{ banner: ICustomBanner }> = ({ banner }) => {
  const classes = styles({ backgroundColor: banner?.color_code || ColorsPalette.primary });
  const [show, setShow] = useState<boolean>(false);

  const handleClick = () => {
    window.localStorage.setItem(`top_campaign_${banner.id}`, 'true');
    setShow(false);
  };

  useEffect(() => {
    setShow(!window.localStorage.getItem(`top_campaign_${banner.id}`));
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Link className={classes.link} href={banner.url || '#'}>
          {banner.text}
        </Link>
        <button className={classes.button} onClick={handleClick}>x</button>
      </div>
    </div>
  );
};

export default CustomBannerTypeTopCampaign;

const styles = makeStyles<Theme, { backgroundColor: string }>((theme) =>
  createStyles({
    button: {
      background: 'transparent',
      color: ColorsPalette.neutral2,
      border: 'none',
      cursor: 'pointer',
      padding: '8px 12px',
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        right: '0px'
      }
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '1440px',
      margin: '0 auto',
      padding: '0 56px',
      [theme.breakpoints.down('md')]: {
        padding: '0 25px 0 20px',
      }
    },
    link: {
      alignItems: 'center',
      padding: '8px 0',
      color: ColorsPalette.neutral2,
      textDecoration: 'none',
      display: 'flex',
      fontSize: 12,
      fontWeight: 500,
      justifyContent: 'center',
      width: '100%'
    },
    wrapper: {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    },
  })
);
