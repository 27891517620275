import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

const styles = makeStyles<Theme, { color?: string; }>((theme) =>
  createStyles({
    notification: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
    },
    notificationContent: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textDecoration: 'none',
      maxWidth: 1280,
      width: '100%',

    },
    notificationContentText: {
      alignItems: 'center',
      display: 'flex',
      flexBasis: 'calc(100% - 30px)',
      justifyContent: 'center',
      padding: '8px',
      textAlign: 'center',
    },
    notificationContentWithClose: {
      marginLeft: 30,
    },
    notificationClose: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 'auto',
      padding: '8px',
    },
    notificationCloseIcon: {
      '& path': {
        fill: (props) => props.color,
      },
    },
    notificationLink: {
      cursor: 'pointer',
    },
  }),
);
export default styles;
