import React from 'react';

import Hidden from '@components/common/Hidden';
import { mobileMode } from '@constants/config';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import Link from '../Link';
import styles from './styles';

interface Params {
  banners: ICustomBanner[];
}

const renderHiddenBlock = (banner: ICustomBanner, children: JSX.Element) => {
  if (mobileMode) {
    if (banner.show_in_app) {
      return <Hidden mdUp>{children}</Hidden>;
    }

    return <>{children}</>;
  }

  return (
    <>
      {banner.show && <Hidden mdDown>{children}</Hidden>}
      {banner.show_in_mobile && <Hidden mdUp>{children}</Hidden>}
    </>
  );
};

const renderLink = (banner: ICustomBanner, children: JSX.Element) => {
  if (!banner.link) {
    return children;
  }

  if (banner.open_new_window) {
    return <a href={banner.link} target="_blank">{children}</a>;
  }

  return <Link href={banner.link}>{children}</Link>;
};

const renderText = (banner: ICustomBanner, classes: any) => {
  const text = (
    <div key={banner.text} className={classes[`notification_${banner.level || 'normal'}`]}>
      {banner.text}
    </div>
  );

  if (banner.link) {
    return renderLink(banner, text);
  }

  return text;
};

const CustomBannerTypeNotification: React.FC<Params> = ({ banners }) => {
  const classes = styles({});

  return (
    <>{banners.map((banner) => renderHiddenBlock(banner, renderText(banner, classes)))}</>
  );
};

export default CustomBannerTypeNotification;
