import * as actions from '@constants/actions/customBanners';
import { ICustomBanner, ICustomBannerClosed, ICustomBannersClose } from '@interfaces/customBanners/actions';
import { getClosedNotification, setClosedNotification } from '@utils/cookies';

export const acNotificationCloseAction: (item: ICustomBannerClosed[]) => ICustomBannersClose = (item) => ({
  payload: item,
  type: actions.CUSTOM_BANNERS_CLOSE,
});

export const acNotificationClose = (item: ICustomBanner) => (dispatch) => {
  const currentState = getClosedNotification();
  const newItem: ICustomBannerClosed = { id: item.uid || '' };
  const currentStateParsed: ICustomBannerClosed[] = currentState && JSON.parse(currentState) || [];
  const newState: ICustomBannerClosed[] = [...currentStateParsed, newItem];

  setClosedNotification(null, JSON.stringify(newState));
  return dispatch(acNotificationCloseAction(newState));
};

export const acNotificationCloseCheck = () => (dispatch) => {
  const currentState = getClosedNotification();
  const currentStateParsed: ICustomBannerClosed[] = currentState && JSON.parse(currentState) || [];

  return currentStateParsed.length ? dispatch(acNotificationCloseAction(currentStateParsed)) : null;
};
