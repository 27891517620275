import {
  CUSTOM_BANNERS_TYPE_BANNER,
  CUSTOM_BANNERS_TYPE_NOTIFICATION,
  CUSTOM_BANNERS_TYPE_TOP_CAMPAIGN,
} from '@constants/actions/customBanners';

import { ECustomBannerType } from '@constants/customBanners';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import { IStore } from '@interfaces/store';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomBannerTypeBanner from './banner';
import CustomBannerTypeNotification from './notification';
import styles from './styles';
import CustomBannerTypeTopCampaign from './TopCampaign';
import { TBannerType } from './types';

interface Params {
  location: ECustomBannerType;
  className?: any;
}

type GroupedBanners = Partial<Record<TBannerType, ICustomBanner[]>>;

const renderBanners = (banners: ICustomBanner[], type: TBannerType): JSX.Element => {
  switch (type) {
    case CUSTOM_BANNERS_TYPE_BANNER:
      return <CustomBannerTypeBanner key={type} banners={banners} />;
    case CUSTOM_BANNERS_TYPE_NOTIFICATION:
      return <CustomBannerTypeNotification key={type} banners={banners} />;
    case CUSTOM_BANNERS_TYPE_TOP_CAMPAIGN:
      return <CustomBannerTypeTopCampaign key={type} banners={banners} />;
    default:
      return <></>;
  }
};

const CustomBanners: React.FC<Params> = ({ location, className }) => {
  const classes = styles({});
  const banners = useSelector<IStore, ICustomBanner[]>((state) => state.pageData.data.customBanners?.[location] || []);
  const groupedBanners: GroupedBanners = {};
  banners.forEach((banner) => {
    const { type } = banner;
    if (!(location === ECustomBannerType.page_header && type === CUSTOM_BANNERS_TYPE_NOTIFICATION)) {
      if (!groupedBanners[type]) {
        groupedBanners[type] = [{ ...banner }];
      } else {
        (groupedBanners[type] as ICustomBanner[]).push(banner);
      }
    }
  });

  if (Object.keys(groupedBanners).length === 0) {
    return <></>;
  }

  return (
    <div className={classNames(classes.banner, className)}>
      {Object.keys(groupedBanners).map((type: any) => renderBanners(groupedBanners[type], type))}
    </div>
  );
};

export default CustomBanners;
