import * as actions from '@constants/actions/priceList';
import { RequestMethods } from '@constants/types';
import { getPriceList } from '@constants/urls';
import { CALL_API } from '../../services/SSRService';

type TPriceListGet = () => any;
export const acPriceListGet: TPriceListGet = () => ({
  [CALL_API]: {
    endpoint: getPriceList(),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.PRICE_LIST_GET_REQUEST,
      actions.PRICE_LIST_GET_SUCCESS,
      actions.PRICE_LIST_GET_FAILED,
    ],
  },
});

export const acPriceListInit = () => (dispatch) => {

  return dispatch(acPriceListGet());
}
